:root {
  --carousel-height: 561px;
}

.slider {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;

  @include desktop {
    margin: 0 auto;
    flex: 1;
    pointer-events: none;
  }
  
  &__wrapper { 
    height: 100%;
  }

  &__container {
    height: 100%;

    @include desktop {
      width: 1314px;
    }
    
    @include desktop-xlarge {
      width: 1314px;
    }
  }

  &__card-container {
    display: flex;
  }

  &__img {
    @include fit-image;
    margin: 0 auto;

    @include desktop {
      margin: 0;
    }
  }

  &__arrow {
    display: none;
    border: 0;
    top: 22px;
    border-radius: 0;
    z-index: 0;
    pointer-events: all;

    @include desktop {
      display: block;
    }

    &--left {
      background-color: $green;
      left: 0px;
      transition: 250ms background-color;
      &:hover {
        background-color: $yellow;
      }

      @include desktop {
        left: 0px;
      }
    }

    &--right {
      left: 44px;
      right: auto;
      background-color: $green;
      transition: 250ms background-color;

      &:hover {
        background-color: $yellow;
      }

      @include desktop {
        left: 44px;
      }
    }
  }

  &__card-text-wrapper {
    position: absolute;
    color: $white;
    bottom: 40px;
    width: 100%;
    padding: 0 20px;
  }

  &__card-text {
    font-size: 2.4rem;
    text-align: left;
    margin-bottom: 10px;

    @include desktop {
      font-size: 3rem;
    }
  }

  &__card-carburante {
    font-size: 1.6rem;

    @include desktop {
      font-size: 2rem;
    }
  }

  &__link {
    height: 561px;
    cursor: pointer;
    pointer-events: all;
    display: block;
    position: relative;

    @include desktop {
      padding-right: 30px;
    }
  }
}


.content {
  &--side-slider {
    width: 100vw;
    overflow: hidden;

    @include desktop-xlarge {
      max-width: 1920px;
    }
  }

  &--slide-group {
    @include desktop {
      display: flex;
      overflow: hidden;
      margin-left: auto;
      width: 92.5%;
    }

    @include desktop-xlarge {
      max-width: 1605px;
      display: flex;
      overflow: hidden;
      margin-left: auto;
    }

    .content__wrapper {
      padding: 30px 50px 0;
      text-align: center;
      align-items: center;

      @include desktop {
        max-width: 482px;
        padding: 0;
        align-items: initial;
        text-align: left;
      }
    }
  }
}
