.call-to-action {
  padding: 48px 30px 40px 30px;
  background-color: $green;
  //margin-top: 106px;
  text-align: center;

  @include desktop {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 48px 60px 40px 60px;
  }

  &__title {
    font-size: 26px;
    line-height: $lHeight5;
    margin-bottom: 30px;
    color: $white;
    font-weight: 600;

    @include desktop {
      margin-bottom: 0;
    }

    &--green {
      color: $green;
      margin-right: 10%;
    }
  }

  &__form {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: fit-content;

    @include desktop {
      margin: unset;
      width: unset;
      margin-left: auto;
      margin-bottom: 7px;
    }
  }

  &__input {
    height: 100%;
    width: 168px;
    padding: 15px 20px;
    outline: 0;
    font-family: glober;
    font-size: 1.6rem;
    border: 0;
    height: 55.5px;

    @include desktop {
      font-size: 1.8rem;
      width: 340px;
    }

    &::placeholder {
      font-size: 1.6rem;

      @include desktop {
        font-size: 1.6rem;
      }
    }
  }

  &__button {
    background-color: $yellow;
    border-left: 0;
    border: 0;
    color: $white;
    font-size: 2.4rem;
    height: 55.5px;
    padding: 12px 20px 15px;

    &:hover .call-to-action__icon {
      transform: translateX(5px);
    }

    &:hover .call-to-action__icon--color {
      fill: $green;
      transition: fill 250ms;
    }
  }

  &__icon {
    transform: translateX(0);
    transition: transform 250ms;
  }

  &__faq-container {
    display: flex;
    justify-content: center;
  }
}

.prova {
  color: $yellow;
}
.prova02 {
  font-weight: bold;
}
.Grassetto {
  font-weight: bold;
}
