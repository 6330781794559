.about-card {
  width: 100%;
  max-width: 300px;
  padding: 32px 28px;
  cursor: pointer;
  margin: 0 auto 34px;
  height: 272px;
  text-align: center;

  &:hover {
    transform: scale(1.025);
    transition: transform 300ms;
    box-shadow: 0px 28px 60px rgba(0, 0, 0, 0.07);
  }

  &:nth-child(4) {
    margin-right: auto;
  }

  @include desktop {
    margin: 0 0 34px;
    margin-right: 30px;

    &:nth-child(even) {
      margin-left: auto;
      margin-right: 0;

      @include desktop-medium {
        margin: 0 30px 0 0;
      }
    }
  }

  @include desktop-medium {
    max-width: 243px;

    &:nth-child(2) {
      margin-right: auto;
      @media screen and (min-width: 1423px) {
        margin-right: 30px;
        margin-left: 0;
      }
    }

    &:nth-child(3) {
      margin-right: auto;
      @media screen and (min-width: 1423px) {
        margin-right: 0;
      }
    }

    &:nth-child(4) {
      margin-left: 0;
      margin-right: auto;
    }
    &:nth-child(5) {
      margin-left: 0;
      margin-right: auto;
      margin-bottom: 0;
    }
    &:nth-child(6) {
      margin-right: auto;
    }
    &:nth-child(7) {
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: 1423px) {
    max-width: 300px;
  }

  &__img-container {
    width: 52px;
    height: 52px;
    margin: 0 auto 28px;

    @include tablet {
      margin-bottom: 28px;
    }

    @include desktop {
      margin: 0 auto;
      margin-bottom: 28px;
    }
  }

  &__img {
    width: 100%;
    @include fit-image;
  }

  &__title {
    color: $green;
    font-size: 2.4rem;
    line-height: $lHeight5;
    margin-bottom: 24px;
  }

  &__text {
    color: $grey;
    font-size: $text4;
    line-height: 26px;
  }
}
