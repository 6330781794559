@font-face {
  font-family: glober;
  src: url('../fonts//Glober/Fontfabric\ -\ GloberRegular.otf')
    format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: glober;
  src: url('../fonts//Glober/Fontfabric\ -\ GloberBold.otf') format('truetype');
  font-weight: 500;
}

* {
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  width: 100%;
}

html {
  font-size: 62.5%;
}

header {
  display: flex;
}

body {
  font-family: glober;
  font-weight: 400;
  color: $black;
  font-size: 1.6rem;
}

img {
  width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: $white;
  font-family: glober;
  font-weight: 400;
}

li {
  list-style: none;
}

i {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  font-family: glober;
}

main {
  position: relative;
}

.bold {
  font-weight: bold;
}

.pdf-link {
  color: $green;
  &:hover {
    color: $green;
    text-decoration: underline;
  }
}

.glide__arrow {
  width: 44px;
  height: 44px;
}
