.slideshow {
  height: 800px;
  width: 100%;
  position: relative;

  @include desktop {
    height: 720px;
  }

  &--no-swipe {
    pointer-events: none;
  }

  &--height-full {
    height: 100%;
  }

  &__container {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    margin-left: 0;
  }

  &__img-wrapper {
    height: 100%;
    width: 100%;
  }

  &__image {
    width: 100%;
    transition: opacity 500ms ease-in-out;
    @include fit-image;
  }

  &__content {
    position: absolute;
    color: $white;
    top: 125px;
    width: 100%;
    pointer-events: all;
    z-index: 3;

    @include desktop {
      top: 0;
      transform: translate(0, 160px);
    }
  }

  &__content-wrapper {
    max-width: 290px;
    padding: 40px 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @include tablet {
      max-width: 1289px;
      width: 85%;
    }

    @include desktop {
      padding: 40px 0;
    }
  }

  &__title {
    font-size: $text6;
    line-height: 52px;
    margin-bottom: 40px;

    @include desktop {
      font-size: $text7;
      line-height: $lHeight8;
    }
  }

  &--height-full {
    &:after {
      content: '';
      position: absolute;
      top: 0%;
      display: block;
      width: 100%;
      height: 800px;
      z-index: 2;
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.7) -19.7%,
        rgba(0, 0, 0, 0) 59.66%
      );

      @include desktop {
        height: 720px;
      }
    }
  }
}
