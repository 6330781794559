.station {
  position: relative;
  padding-top: 10px;
  @include desktop {
    display: flex;
  }

  &__list-wrapper {
    display: none;
    width: 100%;
    max-width: 240px;
    border-right: 2px solid $grey10;
    height: 1712px;

    @include desktop {
      display: block;
    }

    @include desktop-medium {
      max-width: 280px;
    }
    @include desktop-large {
      max-width: 345px;
    }
  }

  &__list {
    max-width: 252px;
  }

  &__list-title {
    padding-bottom: 19px;
    border-bottom: 3px solid $grey10;
    margin-bottom: 49px;
    font-size: 2.2rem;
    line-height: 26px;
    color: $green;
  }

  &__list-item {
    margin-bottom: 24px;
    padding-left: 24px;
    padding-top: 4px;
    font-size: 2rem;
    line-height: $lHeight4;
    border-left: 3px solid $grey10;
    transition: border 200ms;

    &--active {
      border-left: 3px solid $green;

      &:hover {
        border-left: 3px solid $green;
      }

      &:hover .station__list-item-link {
        color: $yellow !important;
      }
    }

    &:hover .station__list-item-link {
      color: $green;
    }

    &:hover {
      border-left: 3px solid $green;
    }
  }

  &__list-item-link {
    color: $grey;

    &--active {
      color: $yellow;

      &:hover {
        color: $yellow !important;
      }
    }
  }

  &__info-wrapper {
    width: 100%;
    text-align: center;
    padding-top: 120px;

    @include desktop {
      padding-top: 0;
      text-align: left;
    }
  }

  &__info {
    width: 100%;
    margin-left: auto;
    text-align: left;

    @include desktop {
      max-width: 570px;
    }

    @include desktop-medium {
      max-width: 770px;
    }

    @include desktop-large {
      max-width: 850px;
    }
  }

  // &__name {
  //   font-size: $text8;
  //   line-height: $lHeight7;
  //   margin-bottom: 30px;

  //   @include desktop {
  //     font-size: $text9;
  //   }
  // }

  &__text {
    font-size: $text4;
    line-height: 26px;
    color: $grey;
    width: 85%;
    max-width: 290px;
    margin: 0 auto 36px;

    @include desktop {
      width: unset;
      max-width: unset;
      margin: unset;
      margin-bottom: 36px;
    }
  }

  &__slide {
    height: 538px;
    margin-bottom: 36px;

    @include desktop {
      pointer-events: none;
    }
  }

  &__slide-track {
    height: 100%;
  }

  &__slide-list {
    height: 100%;
  }

  &__slide-list-item {
    @include fit-image;
  }

  &__slide-arrow {
    width: 45px;
    border-radius: 0;
    border: 0;
    background-color: $green;
    padding: 10px;
    display: none;
    pointer-events: all;
    transition: background-color 200ms;

    @include desktop {
      display: block;
    }

    &:hover {
      background-color: $yellow;
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  &__slide-bullet .glide__bullet {
    pointer-events: none;

    @include desktop {
      pointer-events: all;
    }
    &:hover,
    &:focus {
      background-color: $white;
    }
  }

  // &__details-title {
  //   font-size: 2.8rem;
  //   line-height: $lHeight7;
  //   margin-bottom: 40px;
  // }

  &__map-wrapper {
    height: 560px;
    display: flex;
    margin-bottom: 40px;
  }

  &__map {
    width: 100%;
  }

  &__iframe {
    width: 100%;
    height: 100%;
  }

  &__service-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 85%;
    max-width: 290px;
    margin: 0 auto;

    @include tablet {
      flex-wrap: wrap;
      flex-direction: row;
      align-items: unset;
    }

    @include desktop {
      width: unset;
      max-width: unset;
      margin: unset;
      justify-content: unset;
    }
  }

  &__service-card {
    display: flex;
    align-items: center;
    margin: 0 auto 32px;
    width: 100%;

    @include tablet {
      justify-content: center;
      margin: 0 0 32px;
      width: 20%;
      max-width: 150px;

      // &:nth-child(odd) {
      //   margin-right: 48px;
      // }
    }

    @include desktop {
      justify-content: unset;
      margin-right: 7%;
    }
  }

  &__icon {
    width: 58px;
    height: 40px;
    margin-right: 10px;
    @include desktop {
      margin-right: 12px;
      margin-bottom: 4px;
    }
  }

  &__service-text {
    font-size: 2rem;
    line-height: $lHeight4;
    color: $grey;
  }
}
