.text {
  &--white {
    color: $white;
  }

  &--green {
    color: $green;
  }

  &--grey {
    color: $grey;
  }

  &--mrg-btm-none {
    margin: 0;
  }

  &--mrg-btm-s {
    margin-bottom: 24;
  }

  &--mrg-btm-m {
    margin-bottom: 64px;
  }

  &--mrg-btm-l {
    margin-bottom: 80px;
  }

  &--font-xs {
    font-size: 1.5rem;
  }

  &--font-s {
    font-size: $text4;
  }

  &--font-m {
    font-size: $text5;
  }

  &--font-m-lg {
    @include desktop {
      font-size: $text5;
    }
  }

  &--font-l {
    font-size: 2.4rem;
  }

  &--lineH-m {
    line-height: $lHeight5;
  }

  &--lineH-l {
    line-height: 26px;
  }

  &--lineH-xl {
    line-height: $lHeight6;
  }

  &--width-none {
    max-width: unset;
  }

  &--width-lg {
    @include desktop {
      max-width: 400px;
    }
  }

  &--mrg-xl {
    margin-bottom: 68px;
    @include desktop {
      margin-bottom: 187px;
    }
  }

  &--service {
    @include desktop-medium {
      font-size: 1.5rem;
    }

    @include desktop-large {
      font-size: 1.8rem;
    }
  }
}
