.section {
  &--mrg-xl {
    margin: 650px auto 0;

    @include tablet {
      margin: 380px auto 0;
    }

    @include desktop {
      margin: 280px auto 140px;
    }
  }

  &--slider {
    width: 100%;
    max-width: 290px;
    display: flex;

    @include tablet {
      width: 85%;
      max-width: 1289px;
    }
  }

  &--content {
    width: 85%;
    max-width: 290px;
    margin: 0 auto;
    padding: 106px 0;

    @include tablet {
      max-width: 1289px;
    }
  }

  &--padding-sm {
    padding-bottom: 72px;
  }

  &--no-padding-bottom {
    padding-bottom: 0;
  }

  &--no-margin-bottom {
    margin-bottom: 0;
  }

  &--gallery {
    width: 100%;
    margin: 0 auto;
    //padding: 106px 0;

    @include desktop {
      width: 85%;
      max-width: 1289px;
    }
  }

  // &--station {
  //   width: 85%;
  //   max-width: 290px;
  //   margin: 0 auto;
  //   padding: 106px 0;

  //   @include desktop {
  //     width: unset;
  //     max-width: unset;
  //     margin: unset;
  //     padding: unset;
  //   }
  // }

  &--station-desktop {
    padding: 106px 0;
    @include desktop {
      width: 85%;
      max-width: 1290px;
      margin: 0 auto;
    }
  }
}
