.station-dropdown {
  margin: 0 auto;
  width: 100%;
  max-width: 290px;
  height: inherit;

  &__wrapper {
    position: absolute;
    width: 100%;
    height: 50px;

    @include desktop {
      display: none;
    }
  }

  &--animation {
    transition: color 250ms, background-color 250ms, opacity 250ms,
      visibility 250ms, top 250ms;
  }

  &__input {
    display: none;

    // &:checked + .station-dropdown__title {
    //   background: $green;
    //   color: white;
    // }

    // &:checked ~ .station-dropdown__list {
    //   visibility: visible;
    //   opacity: 1;
    //   top: 0;
    // }
  }

  &__title {
    display: block;
    width: 100%;
    max-width: 290px;
    margin: 0 auto;
    background: $green;
    padding: 15px 20px;
    color: $white;
    font-weight: 500;
    font-size: $text5;
    //border-left: 4px solid $green;
    position: relative;
    z-index: 2;
    text-align: center;

    &--active {
      //background: $green;
      //color: $yellow;
    }

    // &--active + .station-dropdown__line {
    //   max-width: 100%;
    //   width: 100%;
    // }

    &:hover {
      //background: $green;
      background-color: $green10;
      cursor: pointer;
    }

    // &:hover + .station-dropdown__line {
    //   //animation: drawBorder 1s ease forwards;
    //   width: 100%;
    //   max-width: 100%;
    //   background-color: $white;
    //   right: 0;
    // }
  }

  &__list {
    position: relative;
    visibility: hidden;
    opacity: 0;
    top: -20px;
    z-index: 1;

    &--active {
      visibility: visible;
      opacity: 1;
      top: 0;
    }
  }

  &__list-item {
    display: block;
    width: 100%;
    background: $green10;
    padding: 25px 20px 15px;
    color: $white;

    // &:hover {
    //   background: $green;
    //   color: $yellow;
    // }

    // &:hover + .station-dropdown__line {
    //   //animation: drawBorder 1s ease forwards;
    //   cursor: pointer;
    //   width: 100%;
    //   max-width: 100%;
    //   background-color: $yellow;
    //   right: 0;
    // }
  }

  &__list-item-card {
    position: relative;
  }

  &__line {
    height: 2px;
    width: 100%;
    max-width: 0%;
    background-color: $yellow;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    transition: width 1s ease, max-width 1s ease;
  }
}

// @keyframes drawBorder {
//   from {
//     max-width: 0%;
//   }
//   to {
//     max-width: 100%;
//   }
// }
