.servicelist {
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 106px;
  @include desktop {
    flex-direction: unset;
    max-width: 1290px;
    margin: 0 auto;
    padding-bottom: 106px;
  }
  &__list-icon {
    width: 30px;
  }

  &__text {
    font-size: $text4;
    line-height: 26px;
    color: $grey;
    margin-bottom: 36px;

    // &--mrg-l {
    //   margin-bottom: 106px;
    // }

    // &--no-mrg {
    //   margin-bottom: 0;
    // }

    // &--mrg-top-l {
    //   margin-top: 106px;
    // }
  }

  &__list {
    margin-bottom: 60px;
  }

  &__list-item {
    display: flex;
    align-items: center;
    margin-bottom: 26px;
    font-size: $text5;
    line-height: $lHeight3;
    font-weight: bold;
    color: $grey;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__list-icon {
    margin-bottom: 6px;
    margin-right: 16px;
  }

  &__text-wrapper {
    width: 85%;
    max-width: 290px;
    margin: 0 auto;

    @include desktop {
      width: 50%;
      max-width: unset;
      margin: unset;
      padding-right: 8%;
      display: flex;
      flex-direction: column;
    }
  }

  &__glide-wrapper {
    width: 100%;
    margin-bottom: 106px;

    @include desktop {
      width: 50%;
      margin-bottom: 0;
    }
  }

  &__glide {
    height: 100%;
  }

  &__slide-arrow {
    top: 22.2px;
    &--left {
      left: 0;
    }
    &--right {
      left: 45px;
    }
  }
}

// .contenitore {
//   width: 100%;
//   height: 530px;
//   background-color: red;
// }
