.button {
  outline: 0;
  cursor: pointer;
  font-size: $text4;
  line-height: $lHeight3;
  transition: background-color 250ms, color 250ms, border 250ms;
  pointer-events: all;
  text-align: center;

  &--service-list {
    margin-top: auto;
  }

  &--yellow {
    color: $yellow;
  }

  &--white {
    color: $white;
  }

  &--green {
    color: $green;
  }

  &--border-yellow {
    border: 2px solid $yellow;
  }

  &--padding-sm {
    padding: 10px 16px 6px;
  }

  &--padding-md {
    padding: 16px 20px 15px;
  }

  &--padding-l {
    padding: 17px 24px 14px;
  }

  &--bg-transparent {
    background-color: transparent;
    transition: border 250ms, color 250ms;

    &:hover {
      border: 2px solid $green;
      color: $white;
    }
  }

  &--bg-green {
    background-color: $green;

    &:hover {
      background-color: $green10;
    }
  }

  &--width-md {
    max-width: 181px;
  }

  &--width-l {
    max-width: 211px;
  }

  &--display {
    display: block !important;
  }

  &--flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--no-border {
    border: 0;
  }

  &--hamburger {
    color: $yellow;
    background-color: transparent;
    border: 2px solid $yellow;
    cursor: pointer;

    &:hover {
      background-color: $green;
      color: $white;
      border: 2px solid $green;
    }
  }
}
