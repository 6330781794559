// Breakpoints
$small-phone-width: 320px;
$phone-landscape-width: 480px;
$phablet-width: 670px;
$tablet-width: 768px;
$large-tablet-width: 960px;
$desktop-width: 1024px;
$desktop-medium-width: 1280px;
$desktop-large-width: 1440px;
$desktop-xlarge-width: 1920px;

// Text
$text: 1rem;
$text2: 1.2rem;
$text3: 1.4rem;
$text4: 1.6rem;
$text5: 1.8rem;
$text6: 3.6rem;
$text7: 5.6rem;
$text8: 3.2rem;
$text9: 4.4rem;

// LineHeight
$lHeight: 14px;
$lHeight2: 18px;
$lHeight3: 20px;
$lHeight4: 24px;
$lHeight5: 28px;
$lHeight6: 30px;
$lHeight7: 42px;
$lHeight8: 68px;
$lHeight9: 40px;
$lHeight10: 60px;

// Colors
$white: #fff;
$black: #000;

$yellow: #e3a51a;
$green: #28623e;
$green10: #203c2a;
$lightgrey: #f8f8f8;
$grey: #6c6c6c;
$grey10: #e7e7e7;
