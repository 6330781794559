.about {
  position: relative;
  padding-top: 10px;
  @include desktop {
    display: flex;
  }

  &__info-wrapper {
    width: 100%;
    text-align: center;

    @include desktop {
      padding-top: 0;
      text-align: left;
    }
  }

  &__info {
    width: 100%;
    margin: 0 auto;
    text-align: left;

    @include desktop-large {
    }
  }

  // &__name {
  //   font-size: $text8;
  //   line-height: $lHeight7;
  //   margin-bottom: 30px;
  //   color: $green;

  //   @include desktop {
  //     font-size: $text9;
  //   }
  // }

  &__text {
    font-size: $text4;
    line-height: 26px;
    color: $grey;
    margin-bottom: 36px;

    &--mrg-l {
      margin-bottom: 106px;
    }

    &--no-mrg {
      margin-bottom: 0;
    }

    &--mrg-top-l {
      margin-top: 106px;
    }
  }

  &__details-title {
    font-size: 2.8rem;
    line-height: $lHeight7;
    margin-bottom: 40px;
  }

  &__map-wrapper {
    height: 100%;
    margin-bottom: 40px;

    @include tablet {
      display: flex;
    }
  }

  &__map {
    width: 100%;
    height: 350px;
    margin-right: auto;
    margin-bottom: 30px;

    @include tablet {
      width: 48%;
      height: unset;
      margin-bottom: 0;
    }
  }

  &__map-img-container {
    width: 100%;
    height: 100%;

    @include tablet {
      width: 48%;
    }
  }

  &__map-img-wrapper {
    width: 100%;
    height: 350px;

    @include tablet {
      height: 274px;
    }

    &--mrg {
      margin-bottom: 12px;
    }
  }

  &__map-img {
    width: 100%;
    @include fit-image;
  }

  &__iframe {
    width: 100%;
    height: 100%;
  }

  &__service-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include tablet {
      flex-wrap: wrap;
      flex-direction: row;
      align-items: unset;
      margin: 0 auto;
      max-width: 1024px;
    }
  }

  &__service-card {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 32px;

    @include tablet {
      margin: 0 0 32px;
      width: 40%;
      max-width: 300px;
    }

    @include desktop {
      justify-content: unset;
    }
  }

  &__icon {
    width: 40px;
    margin-right: 12px;
  }

  &__service-text {
    font-size: 2rem;
    line-height: $lHeight4;
  }
}
