.box-prezzi {
  position: absolute;
  bottom: 0;
  width: 100%;
  transform: translateY(600px);
  z-index: 3;
  pointer-events: all;

  // &::after {
  //   content: '';
  //   background: linear-gradient(
  //     180deg,
  //     #e9e9e9 0%,
  //     rgba(248, 248, 248, 0) 100%
  //   );
  //   position: absolute;
  //   width: 100%;
  //   height: 170px;
  //   top: 133px;
  // }

  @include tablet {
    transform: translateY(252px);
  }

  @include desktop {
    transform: translateY(160px);
  }

  @include desktop-large {
    transform: translateY(175px);
  }

  &__container {
    padding: 0 74px;
    background-color: $green;
    width: 100%;
    max-width: 290px;
    margin: 0 auto 32px;
    text-align: center;

    @include tablet {
      display: flex;
      width: 85%;
      max-width: 1290px;
      flex-wrap: wrap;
      justify-content: center;
    }

    @include desktop {
      flex-wrap: unset;
      padding: 56px 0;
    }

    @include desktop-large {
      padding: 56px 0;
    }
  }

  &__card {
    color: $white;
    padding: 35px 0;
    border-bottom: 1px solid #cdcdcd;

    &:last-child {
      border-bottom: 0;
    }

    @include tablet {
      padding: 56px 74px;
    }

    @include desktop {
      width: unset;
      max-width: unset;
      padding: 0 56px;
      flex: 1;
      border-bottom: 0;
      border-right: 1px solid #cdcdcd;

      &:last-child {
        border-right: 0;
      }
    }

    @include desktop-large {
      padding: 0 76px;
    }
  }

  &__title {
    text-transform: uppercase;
    margin-bottom: 9px;
    font-size: 22px;
    line-height: $lHeight6;

    @include desktop-large {
      font-size: $text8;
      line-height: $lHeight9;
    }
  }

  &__counter {
    color: $yellow;
    font-weight: 500;
    margin-bottom: 9px;
    font-size: 24px;
    line-height: $lHeight7;
    font-family: glober;

    @include desktop-large {
      font-size: $text9;
      line-height: $lHeight10;
    }
  }

  &__text {
    font-size: $text4;
    line-height: $lHeight2;

    @include desktop-large {
      font-size: $text5;
      line-height: $lHeight3;
    }
  }

  &__disclaimer-container {
    padding: 0 50px;
  }

  &__disclaimer {
    color: $green;
    font-size: $text4;
    line-height: 26px;
    text-align: center;
  }
}
