.form {
  //padding: 60px 40px;
  height: 700px;
  padding: 60px 0;
  background-color: $lightgrey;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //height: fit-content;

  @include desktop {
    height: unset;
    width: 50%;
    padding: 160px 0 160px 100px;
    justify-content: unset;
    //margin-right: 7.5%;
  }

  // @include desktop-large {
  //   padding: 160px 108px;
  // }

  // @include desktop-xlarge {
  //   padding: 116px 315px 116px 140px;
  // }

  &__title {
    display: flex;
    align-items: center;
    font-size: 2rem;
    line-height: $lHeight5;
    color: $green;
    margin-bottom: 38px;
    font-weight: 600;
  }

  &__icon {
    width: 34px;
    height: auto;
    margin-right: 12px;
    margin-bottom: 4px;
  }

  &__input {
    border: 0;
    border-bottom: 1px solid $grey;
    margin-bottom: 36px;
    background-color: transparent;
    padding-bottom: 6px;
    outline: 0;
    max-width: 520px;
    font-family: glober;
    font-weight: 400;
    font-size: 1.6rem;

    @include desktop {
      font-size: 1.8rem;
    }

    &::placeholder {
      font-size: 1.6rem;

      @include desktop {
        font-size: 1.8rem;
      }
    }
  }

  &__textarea {
    border: 0;
    border-bottom: 1px solid $grey;
    background-color: transparent;
    resize: none;
    outline: 0;
    margin-bottom: 50px;
    max-width: 520px;
    font-family: glober;
    font-weight: 400;
    font-size: 1.6rem;

    @include desktop {
      font-size: 1.8rem;
    }

    &::placeholder {
      font-size: 1.6rem;

      @include desktop {
        font-size: 1.8rem;
      }
    }
  }

  &__button-icon {
    width: 15px;
    height: auto;
    margin-right: 12px;
    margin-bottom: 4px;
    transform: translateX(0);
    transition: background-color 250ms, transform 250ms linear;
  }

  &__checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
  }

  &__checkbox {
    width: 25px;
    height: 30px;
    margin: 0;
    margin-right: 20px;
  }

  &__checkbox-text {
    color: $grey;
    margin-top: 4px;

    &--link {
      color: $green;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.button:hover .form__button-icon {
  transform: translateX(5px);
  transition: transform 250ms linear;
}
