.footer {
  @include desktop {
    width: 85%;
    max-width: 1290px;
    margin: 0 auto;
  }
  &__wrapper {
    display: flex;
    width: 75%;
    margin: 0 auto;
    flex-direction: column-reverse;
    @include desktop {
      width: unset;
      margin: unset;
      flex-direction: row;
      background: linear-gradient(to right, $green 48%, $lightgrey 50%);
    }
  }

  &__contact {
    padding: 60px 0;
    //padding: 60px 40px;
    background-color: $green;
    height: 700px;

    @include desktop {
      height: unset;
      width: 50%;
      padding: 160px 100px 160px 0;
      //margin: 0 0 0 7.3%;
    }
  }

  &__text {
    font-size: 2rem;
    line-height: $lHeight5;
    margin-bottom: 36px;
    color: $white;

    &--mrg {
      margin-bottom: 36px;
    }

    &--bold {
      font-weight: 600;
    }

    &--no-mrg {
      margin-bottom: 0;
    }
  }

  &__contact-text {
    font-size: $text4;
    line-height: 26px;
    color: $grey10;
    margin-bottom: 40px;
  }

  &__text-info {
    font-size: $text4;
    line-height: $lHeight5;
    color: $white;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
  }

  &__contact-icon {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    margin-bottom: 4px;
  }

  &__info-wrapper {
    background-color: $green10;
  }

  &__info {
    text-align: center;
    color: $white;
    padding: 43px;
    font-size: $text4;
    line-height: $lHeight6;

    @include desktop {
      font-size: $text5;
    }
  }

  &__info-link {
    color: $yellow;
    &:hover {
      text-decoration: underline;
    }
  }

  &__icons-wrapper {
    display: flex;
    height: 70px;
  }

  &__icon {
    width: 52px;
    margin-right: 20px;
  }

  &__icon-link {
    margin-top: 40px;
  }
}

.footer__bkgr {
  background: linear-gradient(to top, $green 50%, $lightgrey 50%);

  @include desktop {
    background: linear-gradient(to right, $green 48%, $lightgrey 50%);
  }
}
