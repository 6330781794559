.hamburger {
  position: relative;
  z-index: 5;

  &--pos-fixed {
    overflow: hidden;
    height: 100vh;
    @include desktop {
      overflow: inherit;
      height: initial;
    }
  }

  &__line1 {
    transition: transform 0.4s ease-in-out;

    &--selected {
      transform: rotate(45deg) translate(10px, -10px);
    }
  }

  &__line2 {
    transform-origin: 0% 0%;
    transition: transform 0.3s ease-in-out;

    &--selected {
      display: none;
    }
  }

  &__line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;

    &--selected {
      transform: rotate(-45deg) translate(10px, 13px);
    }
  }

  &--pos-fixed {
    overflow: hidden;
    height: 100vh;
    @include desktop {
      overflow: inherit;
      height: initial;
    }
  }

  &__list {
    position: fixed;
    top: 116px;
    right: -100%;
    width: 100%;
    height: calc(100% - 116px);
    display: flex;
    padding-top: 100px;
    transition: right 500ms;
    font-size: $text5;
    line-height: $lHeight4;
    pointer-events: none;

    &--bg-white {
      background-color: $white;
    }

    &--bg-black {
      background-color: $black;
    }
  }

  &--active {
    right: 0;
  }

  &__list-wrapper {
    margin: 0 auto;
  }

  &__list-items {
    text-align: center;
    margin-bottom: 25px;
    font-weight: 500;
    pointer-events: all;
    cursor: pointer;

    &:hover .hamburger__chevron {
      stroke: $yellow !important;
    }

    &--green {
      color: $green;

      &:hover {
        color: $yellow;
      }
    }

    &--white {
      color: $white;

      &:hover {
        color: $yellow;
      }
    }
  }

  &__link-station {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 200px;
    max-width: 200px;
    background-color: $green;
    transform: translateY(0px);
    transform: translateX(10px);
    right: 0;
    padding: 30px 40px;
    // border-radius: 20px;
    pointer-events: all;
  }

  // &__link-station::after {
  //   content: '';
  //   position: absolute;
  //   transform: translateY(-44px);
  //   right: 64px;
  //   width: 0;
  //   height: 0;
  //   border-left: 10px solid transparent;
  //   border-right: 10px solid transparent;
  //   border-bottom: 14px solid $green;
  // }
}

#js-open-hamburger-menu {
  width: 30px;
  height: 25px;
  transform: translate(37px, -4px);
  cursor: pointer;
}
