.navbar {
  background-color: transparent;
  width: 100%;
  height: 116px;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 20;
  top: 0;
  transition: background-color 500ms;

  &__content {
    width: 75%;
    max-width: 1290px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    @include desktop {
      width: 85%;
    }

    // @include desktop-large {
    //   width: 80%;
    // }
  }

  &__logo-wrapper {
    max-width: 190px;
    height: 52px;
    color: $yellow;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin-right: auto;
    font-weight: 500;
  }

  &__logo-link {
    display: flex;
    align-items: center;
  }

  &__img-container {
    max-width: 90px;
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    margin-right: 12px;
  }

  &__img {
    width: 100%;
    object-fit: cover;
  }

  &__logo-text {
    color: $yellow;
    font-weight: 500;
  }

  &__links-wrapper {
    margin-right: auto;
    width: 100%;
    max-width: 670px;
    display: flex;

    @include desktop {
      max-width: 500px;
      padding: 0 15px;
    }

    @media all and (min-width: 1100px) {
      padding: 0;
    }

    @include desktop-medium {
      max-width: 670px;
    }
  }

  &__link {
    margin-right: auto;
    color: $green;
    font-size: $text4;
    line-height: $lHeight3;
    cursor: pointer;
    transition: color 500ms;

    @include desktop {
      font-size: $text3;
    }

    @media all and (min-width: 1100px) {
      font-size: $text4;
    }

    &--green {
      color: $green;
      font-weight: 500;

      &:hover .navbar__chevron {
        stroke: $yellow !important;
      }

      &:hover {
        color: $yellow;
      }
    }

    &--white {
      color: $white;
      font-weight: 500;

      &:hover .navbar__chevron {
        stroke: $yellow !important;
      }
      &:hover {
        color: $yellow;
      }
    }

    &:last-child {
      margin: 0;
    }
  }

  &__chevron {
    width: 16px;
    vertical-align: middle;
  }

  &__links-wrapper,
  .button {
    display: none;

    @include desktop {
      display: flex;
    }
  }

  &__menu {
    padding: 8px;

    @include desktop {
      display: none;
    }
  }

  &__link-hamburger-container {
    position: relative;
    // visibility: hidden;
    // opacity: 0;
    display: none;
    transition: visibility 250ms, opacity 250ms;
  }

  &__link-hamburger-container-active {
    // visibility: visible;
    // opacity: 1;
    display: block;
  }

  &__link-hamburger {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 200px;
    max-width: 200px;
    background-color: $green;
    transform: translateY(35px);
    right: 30px;
    padding: 30px 40px;
  }

  // &__link-hamburger::after {
  //   content: '';
  //   position: absolute;
  //   transform: translateY(-44px);
  //   right: 64px;
  //   width: 0;
  //   height: 0;
  //   border-left: 10px solid transparent;
  //   border-right: 10px solid transparent;
  //   border-bottom: 14px solid $green;
  // }

  &__link-hamburger-list {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: $yellow;
    }
  }

  &__link-hamburger-list-item {
    color: $white;
    font-size: $text4;
    line-height: $lHeight3;
    cursor: pointer;

    &:hover {
      color: $yellow;
    }
  }

  &__scroll {
    background-color: $white;
    box-shadow: 0px 28px 60px rgba(0, 0, 0, 0.2);

    &--bg-black {
      background-color: $black;
    }
  }
}

#js-open-hamburger-menu {
  @include desktop {
    display: none;
  }
}
