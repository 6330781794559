.header-page {
  height: 65vh;
  width: 100%;
  background-color: black;

  &__img {
    width: 100%;
    @include fit-image;
  }
}

.div {
  height: 500px;
}
