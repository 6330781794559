.slide-about {
  height: 450px;
  //margin-bottom: 106px;

  @include tablet {
    height: 500px;
  }

  @include desktop {
    pointer-events: none;
    height: 788px;
  }

  &__track {
    height: 100%;
  }

  &__list {
    height: 100%;
  }

  &__list-item {
    @include fit-image;
  }

  &__arrow {
    width: 45px;
    border-radius: 0;
    border: 0;
    background-color: $green;
    padding: 10px;
    display: none;
    pointer-events: all;
    transition: background-color 200ms;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }

    @include desktop {
      display: block;
    }

    &:hover {
      background-color: $yellow;
    }
  }

  &__bullet .glide__bullet {
    pointer-events: none;
    width: 50px;
    height: 4px;
    border-radius: 0;

    @include desktop {
      pointer-events: all;
    }
    &:hover,
    &:focus {
      background-color: $green;
    }

    &:focus {
      border-color: $green;
    }
  }

  &__bullet .glide__bullet--active {
    background-color: $green;
  }
}
