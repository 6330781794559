.contact {
  width: 100%;

  @include desktop {
    display: flex;
    max-width: 1289px;
  }

  &__card {
    width: 100%;
    max-width: 480px;
    //height: 369px;
    padding: 28px;
    box-shadow: 0px 28px 60px rgba(0, 0, 0, 0.2);
    margin: 0 auto 40px;

    @include desktop {
      margin: 0 0;
      max-width: 600px;
      width: 49%;
      &:first-child {
        margin-right: auto;
      }
    }

    // @include desktop-medium {
    //   max-width: 600px;
    // }

    @include desktop-xlarge {
      max-width: 700px;
    }
  }

  &__card-top {
    display: flex;
    align-items: center;
  }

  &__icon {
    width: 16px;
    margin-right: 20px;
  }

  &__title {
    margin-top: 6px;
    font-size: 2.2rem;
    line-height: 26px;
    color: $green;
  }

  &__text {
    font-size: $text4;
    line-height: 26px;
    color: $grey;
    margin-bottom: 36px;
    text-align: left;
  }

  &__card-line {
    border: 1px solid rgba(0, 0, 0, 0.5);
    margin-top: 20px;
    margin-bottom: 32px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__card-content {
    font-size: $text5;
    line-height: $lHeight5;
    color: $grey;
    margin-bottom: 10px;
  }

  &__phone {
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 32px;
    margin-bottom: 10px;
    color: $green;
  }

  &__email {
    font-size: $text5;
    line-height: $lHeight5;
    margin-bottom: 20px;
    color: $green;
  }

  &__address {
    font-size: $text5;
    line-height: $lHeight5;
    margin-bottom: 32px;
  }

  &__card-info {
    font-size: $text5;
    line-height: $lHeight5;
    margin-bottom: 20px;
  }

  &__list {
    font-size: $text5;
    line-height: $lHeight3;
    font-weight: 500;
  }

  &__list-item {
    list-style: url('../assets/greycircle.svg');
    list-style-position: inside;
    margin-bottom: 12px;
    margin-right: 12px;
  }

  &__display-mobile {
    @include desktop-medium {
      display: none;
    }
  }

  &__display-desktop {
    display: none;
    @include desktop-medium {
      display: flex;
    }
  }

  &__content-wrapper {
    display: flex;
  }

  &__content-left {
    margin-right: auto;
  }

  &__display-desktop > .contact__card-line {
    margin-bottom: 20px;
    margin-top: 0;
    margin-right: 24px;
  }

  &__content-right {
    @include desktop-medium {
      margin-right: 20px;
    }

    @include desktop-xlarge {
      margin-right: 99px;
    }
  }
}
