//============================
// #TOOLS: Mixins
//============================

//  Responsive media query mixins.
@mixin small-phone {
  @media screen and (min-width: $small-phone-width) {
    @content;
  }
}

@mixin phone-landscape {
  @media screen and (min-width: $phone-landscape-width) {
    @content;
  }
}

@mixin phablet {
  @media screen and (min-width: $phablet-width) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $tablet-width) {
    @content;
  }
}

@mixin large-tablet {
  @media screen and (min-width: $large-tablet-width) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop-width) {
    @content;
  }
}

@mixin desktop-medium {
  @media screen and (min-width: $desktop-medium-width) {
    @content;
  }
}

@mixin desktop-large {
  @media screen and (min-width: $desktop-large-width) {
    @content;
  }
}

@mixin desktop-xlarge {
  @media screen and (min-width: $desktop-xlarge-width) {
    @content;
  }
}

@mixin fit-image {
  height: 100%;
  object-fit: cover;
}
