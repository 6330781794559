.content {
  margin: 0 auto;
  height: auto;

  @include desktop {
    display: flex;
    margin: 0;
    // margin-right: auto;
  }

  &__wrapper {
    max-width: 480px;
    padding-top: 60px;
    text-align: left;
    margin: 0 auto 100px;
    display: flex;
    flex-direction: column;

    @include desktop {
      margin-bottom: 0;
      max-width: 300px;
    }

    @include desktop-medium {
      max-width: 480px;
    }
  }
  &__title {
    color: $green;
    font-size: $text6;
    margin-bottom: 36px;
    line-height: 52px;

    @include desktop {
      line-height: $lHeight8;
      font-size: $text7;
    }

    &--no-mrg {
      margin-bottom: 0;
    }

    &--station {
      width: 85%;
      max-width: 290px;
      margin: 0 auto 36px;

      @include desktop {
        width: unset;
        max-width: unset;
        margin: unset;
        margin-bottom: 36px;
      }
    }
  }

  &__card-container {
    text-align: center;
    margin: 0 auto;

    @include tablet {
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: wrap;
      max-width: 770px;

      &--no-reverse {
        flex-direction: row;
      }
    }

    @include desktop {
      text-align: left;
    }

    @include desktop-medium {
      max-width: unset;
    }
  }

  &__wrapper-text {
    max-width: 480px;
    margin: 0 auto;

    @include desktop {
      margin: 0 auto;
    }

    @include desktop-medium {
      margin: 0 0 0 auto;

      &--margin-reverse {
        margin: 0 auto 0 0;
      }
    }
  }
}
